import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IdiomaGR } from "@sharedV11/classes/tarifaResponse/idiomaGR";
import { InternetGroup } from "@sharedV11/classes/tarifaResponse/internetgroup";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { EditInternetGroupService } from "@sharedV11/services/components/internetGroups/edit-internet-group.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-group",
  templateUrl: "./edit-group.component.html",
  styleUrls: ["./edit-group.component.scss"],
})
export class EditGroupComponent implements OnInit, AfterViewInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editorListaIdiomas: Array<IdiomaGR> = [];
  idiomasApp: Array<IdiomaGR> = [];
  editInternetGroupForm: FormGroup;

  internetgroup: InternetGroup;
  TiposReservas: any[] = [];
  EnlacesMenu: any[] = [];

  //https://www.npmjs.com/package/angular-dual-listbox
  filter = true;
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  source = [];
  confirmados = [];

  conectionId;
  cplist = [];

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  constructor(
    private editInternetGroupService: EditInternetGroupService,
    private servicioAPI: ConectarApiService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private tokenService: ApiTokenService,
    private alertaService: AlertService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.getTipoReserva();

    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };

    this.initializeFormWithoutData();
  }

  ngAfterViewInit() {
    // this.pkId="4"
    this.manageForm(this.pkId, this.mode);
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  get formControls() {
    return this.editInternetGroupForm.controls;
  }

  ver() {}
  manageForm(pkId, mode) {
    if (mode == "I") {
      this.internetgroup = this.editInternetGroupForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.getEnlacesMenu();
      this.getIdiomas([], this.mode);
      this.cdRef.detectChanges();
      this.valuedChanged();
    }
    if (pkId != null) {
      this.findinternetGroup(pkId);
    }
  }

  findinternetGroup(pkId) {
    let ClaveEmpresa = this.tokenService.getConfig("chClaveEmpresa");
    let ProyectoOnLine = this.tokenService.getConfig("chProyectoOnLine");
    let ConexionEntorno = this.tokenService.getConfig("chEntornoConexion");
    this.editInternetGroupService
      .findIternetGroupByPkId(
        pkId,
        ClaveEmpresa,
        ProyectoOnLine,
        ConexionEntorno
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (userApi) => {
          // let user:User =  userApi["DatosResult"]["tipoUsuarios"][0];
          let internetgroupdata: InternetGroup =
            userApi.DatosResult.listaGrupoInternet[0];
          if (internetgroupdata != null) {
            this.changeValueForm(internetgroupdata);
            this.internetgroup = this.editInternetGroupForm.value;
            this.internetgroup = internetgroupdata;
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(
              this.internetgroup
            );
            //this.confirmed = internetgroupdata.LstEnlacesMenu;

            /*
        let src = [];
        let con= [];

        internetgroupdata.LstEnlacesMenu.forEach(em=> {

          if (em.Asignado=="1") con.push({pkId:em.pkId,Nombre:em.Nombre,ConexionId:em.ConexionId});
          if (em.Asignado=="0") src.push({pkId:em.pkId,Nombre:em.Nombre,ConexionId:em.ConexionId});
        });
        
        this.source = [...src, ...con];
        this.confirmados = con;
        */

            this.source = internetgroupdata.LstEnlacesMenu;
            this.confirmados = internetgroupdata.LstEnlacesMenu.filter(
              (f) => f.Asignado == "1"
            );
            if (this.confirmados.length > 0) {
              this.conectionId = this.confirmados[0].ConexionId;
            }

            /*        let url = "https://tickets.mapfre.iacpospre.com/reservarEntradas?ck=";
        let jsonurl = "";
        this.confirmados.forEach(c => {
          jsonurl = '{"empresa":"PEDRERA","m":"'+c["Nombre"]+'","gi":"355","lg":"ca"}';
          this.cplist.push( "Opciones: "+url+this.funcionEncript(jsonurl));
        }); */
            this.cdRef.detectChanges();

            this.getIdiomas(internetgroupdata.Idiomas, this.mode);
            this.getListaEnlaces();

            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  funcionEncript(json) {
    const clavePrivada = "3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·";
    let final = "";
    for (let index = 1; index <= json.length; index++) {
      const tokenMio = this.Mid(json, index, 1);
      const codigo = this.Mid(
        clavePrivada,
        ((index - 1) % clavePrivada.length) + 1,
        1
      );
      // tslint:disable-next-line:no-bitwise
      const a = (this.Asc(codigo) ^ this.Asc(tokenMio)).toString(16);
      final = final + this.Right("0" + a, 2);
    }
    return final;
  }

  Mid(strMid, intBeg, intEnd) {
    if (strMid === null || strMid === "" || intBeg < 0) {
      return "";
    }
    intBeg -= 1;
    if (intEnd === null || intEnd === "") {
      return strMid.substr(intBeg);
    } else {
      return strMid.substr(intBeg, intEnd);
    }
  }

  Chr(num) {
    const res = String.fromCharCode(num);
    return res;
  }

  Asc(str) {
    return str.charCodeAt(0);
  }

  Right(str, n) {
    if (n <= 0) {
      return "";
    } else if (n > String(str).length) {
      return str;
    } else {
      const iLen = String(str).length;
      return String(str).substring(iLen, iLen - n);
    }
  }

  initializeFormWithData(ig: InternetGroup) {
    this.editInternetGroupForm = this.fb.group({
      pkId: [ig.pkId],
      Nombre: [ig.Nombre],
      Idioma: [ig.Idioma],
      Maximo: [ig.Maximo],
      Minimo: [ig.Minimo],
      TipoReservaId: [ig.TipoReservaId],
      ValidarGratuitas: [ig.ValidarGratuitas],
      Orden: [ig.Orden],
      Descripcion: [ig.Descripcion],
    });
  }

  changeValueForm(ig: InternetGroup) {
    this.formControls["pkId"].setValue(ig.pkId);
    this.formControls["Nombre"].setValue(ig.Nombre);
    this.formControls["Idioma"].setValue(ig.Idioma);
    this.formControls["Maximo"].setValue(ig.Maximo);
    this.formControls["Minimo"].setValue(ig.Minimo);
    this.formControls["TipoReservaId"].setValue(ig.TipoReservaId);
    this.formControls["ValidarGratuitas"].setValue(ig.ValidarGratuitas);
    this.formControls["Orden"].setValue(ig.Orden);
    this.formControls["Descripcion"].setValue(ig.Descripcion);
    //this.formControls["Prefijo"].setValue(ig.Prefijo)
  }
  initializeFormWithoutData() {
    this.editInternetGroupForm = this.fb.group({
      pkId: [""],
      Nombre: ["", Validators.required],
      Idioma: [""],
      Descripcion: [""],
      Orden: [""],
      Maximo: [""],
      Minimo: [""],
      TipoReservaId: [""],
      ValidarGratuitas: [""],
      CentrosIds: [""],
      // Prefijo:[""]
    });
  }

  stringToBoolean(value) {
    if (typeof value !== "boolean") {
      if (value.toLowerCase() == "true") {
        value = true;
      } else {
        value = false;
      }
    }
    return value;
  }

  getTipoReserva() {
    this.servicioAPI.getTiposReserva().subscribe(
      (TiposReservas) => {
        this.TiposReservas = TiposReservas.DatosResult.listaTipoReserva;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  getEnlacesMenu() {
    this.servicioAPI.getEnlacesMenu().subscribe(
      (EnlacesMenu) => {
        this.source = EnlacesMenu.DatosResult.LstEnlacesMenu;
        //this.source  = [{"pkId":"10", "Nombre":"prueba"}];
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  getListaEnlaces() {
    this.confirmados.forEach((c) => {
      var cplistlang = [];
      c.ListUrlVentaOnline.forEach((l) => {
        cplistlang.push({
          idioma: l.idoma,
          urlparams: "",
          url: l.UrlVentaOnline,
          grupo: this.translation.Copiar + " " + c["Nombre"],
        });
      });
      this.cplist.push(cplistlang);
    });
  }

  getIdiomas(idiomasgrupo, mode) {
    this.servicioAPI.getIdiomas().subscribe(
      (losidiomas) => {
        let jsonurl = "";
        /*       this.confirmados.forEach(c => {
        var cplistlang = [];
        losidiomas.DatosResult.ListaIdiomas.forEach(l => {
          let url = environment.TICKETS_URL+"reservarEntradas?ck=";
          jsonurl = '{"empresa":"'+environment.chClaveEmpresa+'","m":"'+c["Nombre"]+'","gi":"'+this.pkId+'","lg":"'+l.CodIdioma+'"}';
          cplistlang.push( {"idioma":l.Nombre, "urlparams":this.funcionEncript(jsonurl), "url":url ,"grupo":this.translation.Copiar +" "+ c["Nombre"]} );
        });
       this.cplist.push(cplistlang);
      }); */

        losidiomas.DatosResult.ListaIdiomas.map((idioma) => {
          let idiomaselect = new IdiomaGR();
          idiomaselect.CodIdioma = idioma.CodIdioma;
          idiomaselect.Nombre = idioma.Nombre;
          this.idiomasApp.push(idiomaselect);

          let idiomaObj = idiomasgrupo.find(
            (i) => i.CodIdioma == idioma.CodIdioma
          );

          this.editorListaIdiomas.push(idioma);
          this.editInternetGroupForm.addControl(
            "nombre" + idioma.CodIdioma,
            new FormControl(idiomaObj ? idiomaObj.Nombre : "")
          );
          this.editInternetGroupForm.addControl(
            "descripcion" + idioma.CodIdioma,
            new FormControl(idiomaObj ? idiomaObj.Descripcion : "")
          );
          this.editInternetGroupForm.addControl(
            "descripcion1" + idioma.CodIdioma,
            new FormControl(idiomaObj ? idiomaObj.Descripcion1 : "")
          );
          this.editInternetGroupForm.addControl(
            "descripcion2" + idioma.CodIdioma,
            new FormControl(idiomaObj ? idiomaObj.Descripcion2 : "")
          );
        });

        this.internetgroup = this.editInternetGroupForm.value;
        this.valuedChanged();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  submit() {
    let internetgroup: InternetGroup = this.editInternetGroupForm.value;
    internetgroup.Idiomas = [];
    this.editorListaIdiomas.forEach((i) => {
      let enIdioma: IdiomaGR = new IdiomaGR();
      enIdioma.CodIdioma = i.CodIdioma;
      enIdioma.Nombre = internetgroup["nombre" + i.CodIdioma];
      enIdioma.Descripcion = internetgroup["descripcion" + i.CodIdioma];
      enIdioma.Descripcion1 = internetgroup["descripcion1" + i.CodIdioma];
      enIdioma.Descripcion2 = internetgroup["descripcion2" + i.CodIdioma];
      internetgroup.Idiomas.push(enIdioma);
    });
    let enlacesmenu = [];
    if (this.confirmados.length == 0) {
      enlacesmenu.push({ pkId: "", ConexionId: this.conectionId });
    }
    this.confirmados.forEach((c) => {
      enlacesmenu.push({ pkId: c.pkId, ConexionId: c.ConexionId });
    });

    internetgroup.EnlacesMenu = enlacesmenu;
    this.manageApi(internetgroup, this.mode);
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.internetgroup);
  }

  manageApi(internetgroup: InternetGroup, mode) {
    this.loading = true;
    internetgroup.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    this.insertDataInObj();
    switch (mode) {
      case "I":
        internetgroup.pkId = "0";
        this.InsGrupoInternet(internetgroup);
        break;
      case "U":
        this.UpdGrupoInternet(internetgroup);
        break;
      case "D":
        internetgroup.pkId = "0";
        this.InsGrupoInternet(internetgroup);
        break;
      default:
        break;
    }
  }

  UpdGrupoInternet(internetgroup) {
    this.editInternetGroupService
      .updGruposInternet(internetgroup, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          if (response.DatosResult != null) {
            this.save();
            this.destroy();
          } else {
            this.alertaService.error(response.Mensajes[0].DescripcionMensaje);
            this.destroy();
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  InsGrupoInternet(internetgroup) {
    this.editInternetGroupService
      .insGruposInternet(internetgroup, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          if (response.DatosResult != null) {
            this.save();
            this.alertaService.success("Datos Guardados Correctamente")
            this.destroy();
          } else {
            this.alertaService.error(response.Mensajes[0].DescripcionMensaje);
            this.destroy();
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Descripcion =
      evento;
    //
  }

  valuedChanged() {
    this.editInternetGroupForm.valueChanges.subscribe((value) => {
      if (this.internetgroup != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.internetgroup)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  insertDataInForm() {
    if (<HTMLInputElement>document.getElementById("TipoReservaId-hidden"))
      this.editInternetGroupForm
        .get("TipoReservaId")
        .setValue(
          (<HTMLInputElement>document.getElementById("TipoReservaId-hidden"))
            .value
        );
  }
  insertDataInObj() {
    this.internetgroup.TipoReservaId = (<HTMLInputElement>(
      document.getElementById("TipoReservaId-hidden")
    )).value;
  }
  closeInputFilter(enventValues) {
    this.insertDataInForm();
  }
}
