import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { SuperClassComponent } from '@sharedV11/super-class-edit/super-class/super-class.component';
import { EditGruposTarifasComponent } from './edit-grupos-tarifas/edit-grupos-tarifas.component';

@Component({
  selector: 'app-grupos-tarifas',
  templateUrl: './grupos-tarifas.component.html',
  styleUrls: ['./grupos-tarifas.component.scss']
})
export class GruposTarifasComponent extends SuperClassComponent {

  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(
      panelService,
      cdRef
    );
  }


  openPanel() {
    this.customanpanel = EditGruposTarifasComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }

}
