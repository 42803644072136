import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrupoTipoBono } from "@sharedV11/classes/EntradasPromociones/GrupoTipoBono/GrupoTipoBonoRespuesta/grupo-tipo-bono-respuesta.model";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { GrupoTipoBonoV11Service } from "@sharedV11/services/components/entradas-y-promociones/grupo-tipo-bono-v11/grupo-tipo-bono-v11.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-grupo-tipo-bono-v11",
  templateUrl: "./edit-grupo-tipo-bono-v11.component.html",
  styleUrls: ["./edit-grupo-tipo-bono-v11.component.scss"],
})
export class EditGrupoTipoBonoV11Component implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;

  grupoTipoBonoForm: FormGroup;
  grupoTipoBono: GrupoTipoBono;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  constructor(
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private gruposTipoBonoService: GrupoTipoBonoV11Service,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);
  }

  initializeFormWithoutData() {
    this.grupoTipoBonoForm = this.fb.group({
      pkId: [""],
      NombreGrupo: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]],
      Descripcion: ["", [Validators.pattern(/[A-Za-z]/)]],
      CentrosIds: [""],
    });
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.grupoTipoBono = this.grupoTipoBonoForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.grupoTipoBono);

      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      this.findGrupoTipoBono(pkId);
    }
  }

  findGrupoTipoBono(pkId) {
    this.gruposTipoBonoService
      .findGrupoTipoBonoByPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userApi) => {
        let gtb: GrupoTipoBono =
          userApi["DatosResult"]["customanGrupoTipoBono"][0];

        if (gtb != null) {
          this.changeValueForm(gtb);
          this.grupoTipoBono = this.grupoTipoBonoForm.value;
          this.grupoTipoBono.CentrosIds = gtb.CentrosIds;
          this.valuedChanged();
          this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
          this.centrosAsignados = this.gsCentros.daTiposCentros(
            this.grupoTipoBono
          );
          this.loading = false;
        }
      });
  }

  valuedChanged() {
    this.grupoTipoBonoForm.valueChanges.subscribe((value) => {
      if (this.grupoTipoBono != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.grupoTipoBono)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }

  get formControls() {
    return this.grupoTipoBonoForm.controls;
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save() {
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.grupoTipoBono);
  }

  changeValueForm(gtb: GrupoTipoBono) {
    this.formControls["pkId"].setValue(gtb.pkId);
    this.formControls["NombreGrupo"].setValue(gtb.NombreGrupo);
    this.formControls["Descripcion"].setValue(gtb.Descripcion);
  }

  submit() {
    this.grupoTipoBonoForm.markAllAsTouched();
    let gtb: GrupoTipoBono = this.grupoTipoBonoForm.value;
    if (this.grupoTipoBonoForm.valid) {
      this.manageApi(gtb, this.mode);
    }
  }

  manageApi(gtb: GrupoTipoBono, mode) {
    gtb.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        gtb.pkId = "0";
        this.InsTipoBono(gtb);
        break;
      case "U":
        //update
        this.UpdTipoBono(gtb);

        break;
      case "D":
        //duplicate
        gtb.pkId = "0";
        this.InsTipoBono(gtb);

        break;

      default:
        break;
    }
  }
  UpdTipoBono(gtp) {
    this.gruposTipoBonoService
      .updGrupoTipoBono(gtp, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  InsTipoBono(gtp) {
    this.gruposTipoBonoService
      .insGrupoTipoBono(gtp, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach((element) => {
          this.snackvar_service.openSnackBar(
            element.DescripcionMensaje,
            "",
            "red-snackbar",
            element.CodigoMensaje
          );
        });
      } else {
        this.snackvar_service.openSnackBar(
          this.translation["MensajeSuccessSnackBar"],
          "",
          "green-snackbar"
        );
      }
    }
  }

  existErrors() {
    return this.formControls.NombreGrupo.errors ||
      this.formControls.Descripcion.errors
      ? true
      : false;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.grupoTipoBonoForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (fc_pv.hasError("pattern")) {
        return this.translation["SoloLetras"];
      }
    }
    return "Error";
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }
}
