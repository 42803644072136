<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
	<app-buttons
		(save)="submit()"
		(close)="destroy()"
		(discard)="discard()"
		[buttonsWrap]="'panel'"
		[modificar]="modificar"
	></app-buttons>
	<div class="editor-registro">
		<div class="divForm">
			<form [formGroup]="editRegistroForm" (onSubmit)="submit()">
				<div class="form-group row">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.PlataformasDeVenta }}</mat-label>
						<mat-select
							multiple
							placeholder="{{ translation.PlataformasDeVenta }}"
							formControlName="tipoControlPlataforma"
							(selectionChange)="actualizarPlataformasSeleccionadas()"
						>
							<mat-option value="1">{{ translation.PuntoVentaKORE }}</mat-option>
							<mat-option value="2">{{ translation.Internet }}</mat-option>
							<mat-option value="3">{{ translation.Administracion }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group row">
					<mat-label
						>{{ translation.Activa }}
						<mat-slide-toggle
							name="Activa"
							formControlName="Activa"
						></mat-slide-toggle>
					</mat-label>
				</div>

				<div class="form-group">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.AsignadoA }}</mat-label>
						<mat-select
							placeholder="{{ translation.AsignadoA }}"
							formControlName="NombreTabla"
							(selectionChange)="actualizarAsignadoIdSelectValues()"
						>
							<mat-option value="tbClientes">{{ translation.Clientes }}</mat-option>
							<mat-option value="tbGruposClientes">{{ translation.GruposClientes }}</mat-option>
							<mat-option value="tbTiposClientes">{{ translation.TiposClientes }}</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.Asignado }} {{ translation.Id }}</mat-label>
						<mat-select
							placeholder="{{ translation.Asignado }} {{ translation.Id }}"
							formControlName="PkIdTabla"
						>
							<mat-option
								value=""
							>
								--
							</mat-option>
							<mat-option
								*ngFor="let asignadoIdOption of asignadoIdSelectValues"
								value="{{ asignadoIdOption.Id }}"
							>
								{{ asignadoIdOption.Nombre }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.AplicadoA }}</mat-label>
						<mat-select
							placeholder="{{ translation.AplicadoA }}"
							formControlName="NombreTablaEntrada"
							(selectionChange)="actualizarAplicadoIdSelectValues()"
						>
							<mat-option value="tbTiposEntrada">{{ translation.TiposEntrada }}</mat-option>
							<mat-option value="tbGruposTiposEntrada">{{ translation.GruposTiposEntrada }}</mat-option>
							<mat-option value="tbTipoBono">{{ translation.TiposBono }}</mat-option>
							<mat-option value="tbGrupoTipoBono">{{ translation.GrupoTipoBono }}</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.Aplicado }} {{ translation.Id }}</mat-label>
						<mat-select
							placeholder="{{ translation.AplicadoA }} {{ translation.Id }}"
							formControlName="iTablaEntradaId"
						>
						<mat-option
							value=""
						>
							--
						</mat-option>
						<mat-option
							*ngFor="let aplicadoIdOption of aplicadoIdSelectValues"
							value="{{ aplicadoIdOption.Id }}"
						>
							{{ aplicadoIdOption.Nombre }}
						</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.TipoPeriodo }}</mat-label>
						<mat-select
							placeholder="{{ translation.TipoPeriodo }}"
							formControlName="TipoPeriodo"
						>
							<mat-option value="-1">{{ translation.TodoSinLimite }}</mat-option>
							<mat-option value="1">{{ translation.Diario }}</mat-option>
							<mat-option value="2">{{ translation.Mensual }}</mat-option>
							<mat-option value="3">{{ translation.MesNatural }}</mat-option>
							<mat-option value="4">{{ translation.Anual }}</mat-option>
							<mat-option value="5">{{ translation.AnioNatural }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.FechaVisibleInicio }}</mat-label>
						<input
							[max]="getFechaFin()"
							matInput
							[matDatepicker]="FechaVisibleInicioCtrl"
							placeholder="{{ translation.FechaVisibleInicio }}"
							formControlname="FechaVisibleInicio"
							(dateChange)="setDate($event, 'FechaVisibleInicio')"
							[value]="getFechaInicio()"
						/>
						<mat-datepicker-toggle
							matSuffix
							[for]="FechaVisibleInicioCtrl"
						></mat-datepicker-toggle>
						<mat-datepicker #FechaVisibleInicioCtrl></mat-datepicker>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.FechaVisibleFin }}</mat-label>
						<input
							[min]="getFechaInicio()"
							matInput
							[matDatepicker]="FechaVisibleFinCtrl"
							placeholder="{{ translation.FechaVisibleFin }}"
							formControlname="FechaVisibleFin"
							(dateChange)="setDate($event, 'FechaVisibleFin')"
							[value]="getFechaFin()"

						/>
						<mat-datepicker-toggle
							matSuffix
							[for]="FechaVisibleFinCtrl"
						></mat-datepicker-toggle>
						<mat-datepicker #FechaVisibleFinCtrl></mat-datepicker>
					</mat-form-field>
				</div>

				<div class="form-group row">
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Cantidad }}</mat-label>
						<input
							matInput
							formControlName="Cantidad"
							appPositiveInteger
							placeholder="{{ 0 }}"
						/>
					</mat-form-field>
				</div>

				<div class="form-group row">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.ProcesoVenta }}</mat-label>
						<mat-select
							placeholder="{{ translation.ProcesoVenta }}"
							formControlName="ProcesoVentaId"
						>
						<mat-option
							value=""
						>
							--
						</mat-option>
						<mat-option
							*ngFor="let procesoVentaOption of procesosVenta"
							value="{{ procesoVentaOption.Id }}"
						>
							{{ procesoVentaOption.Nombre }}
						</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group row" style="margin: 10px 10px;">
					<mat-label
						>{{ translation.Validar }} "{{ translation.AplicadoA }}""
						<mat-slide-toggle
							name="TablaValidar"
							formControlName="TablaValidar"
						></mat-slide-toggle>
					</mat-label>
				</div>

				<div class="form-group row">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.TipoDocumento }}</mat-label>
						<mat-select
							placeholder="{{ translation.TipoDocumento }}"
							formControlName="TipoDocumento"
							name="TipoDocumento"
							multiple
						>
							<mat-option value="*">{{ translation.Ninguno }}</mat-option>
							<mat-option value="I">{{ translation.CodigoCliente }}</mat-option>
							<mat-option value="D">DNI</mat-option>
							<mat-option value="E">Email</mat-option>
							<mat-option value="N">{{ translation.Nombre }}</mat-option>
							<mat-option value="Y">{{ translation.Numero }} Eyca</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group row">
					<mat-label> {{ translation.ValidarPor }}: </mat-label>
					<mat-radio-group formControlName="CantidadPor">
						<mat-radio-button value="visita" style="margin: 0px 10px 0px 0px;">
							{{ translation.PorFechaDeVisita }}
						</mat-radio-button>
						<mat-radio-button value="venta" style="margin: 0px 10px 0px 0px;">
							{{ translation.PorFechaDeCompra }}
						</mat-radio-button>
					</mat-radio-group>
				</div>

				<div class="form-group row">
					<mat-label
						>{{ translation.Validar }} {{ translation.Espectaculo }}
						<mat-slide-toggle
							name="ValidarEspectaculo"
							formControlName="ValidarEspectaculo"
							(toggleChange)="onChangeValidarEspectaculo()"
						></mat-slide-toggle>
					</mat-label>
				</div>

				<div
					class="form-group"
					*ngIf="editRegistroForm.get('ValidarEspectaculo').value === true"
				>
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.Espectaculo }}</mat-label>
						<mat-select
							placeholder="{{ translation.Espectaculo }}"
							formControlName="EspectaculoId"
						>
						<mat-option
							value=""
						>
							--
						</mat-option>
						<mat-option
							*ngFor="let espectaculoOption of espectaculos"
							value="{{ espectaculoOption.Id }}"
						>
							{{ espectaculoOption.Nombre }}
						</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-label
						>{{ translation.TodasLasFechas }}
						<mat-slide-toggle
							name="TodasLasFechas"
							formControlName="TodasLasFechas"
							(toggleChange)="onChangeTodasLasFechasToggle()"
						></mat-slide-toggle>
					</mat-label>
				</div>

				<div
					class="form-group"
					*ngIf="editRegistroForm.get('ValidarEspectaculo').value === true && editRegistroForm.get('TodasLasFechas').value === false"
				>
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.FechaSesion }}</mat-label>
						<input
							matInput
							[matDatepicker]="FechaSesionCtrl"
							placeholder="{{ translation.FechaSesion }}"
							formControlname="FechaSesion"
							(dateChange)="setDate($event, 'FechaSesion')"
							[value]="getFechaSesion()"

						/>
						<mat-datepicker-toggle
							matSuffix
							[for]="FechaSesionCtrl"
						></mat-datepicker-toggle>
						<mat-datepicker #FechaSesionCtrl></mat-datepicker>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Hora }} {{ translation.Sesion }}</mat-label>
						<input
							matInput
							formControlName="HoraSesion"
							class="time-picker"
							placeholder="{{ translation.Hora }} {{ translation.Sesion }}"
							type="time"
							(change)="onChangeHour()"
						/>
					</mat-form-field>
				</div>

				<div class="form-group row">
					<mat-label
						>{{ translation.NominarTipoBono }}
						<mat-slide-toggle
							name="NominarEntradasPromo"
							formControlName="NominarEntradasPromo"
						></mat-slide-toggle>
					</mat-label>

					<mat-label
						>{{ translation.nominarentradas }}
						<mat-slide-toggle
							name="NominarEntradas"
							formControlName="NominarEntradas"
						></mat-slide-toggle>
					</mat-label>
				</div>

				<div style="margin: 20px 5px 0 5px; text-align: center">
					<mat-label>{{ translation.Centros }}</mat-label>

					<customan-dual-list
						[source]="centrosSinAsignar"
						[(destination)]="centrosAsignados"
						key="pkId"
						display="Nombre"
						[format]="format"
						[filter]="true"
					>
					</customan-dual-list>
				</div>
			</form>
		</div>
	</div>
</div>
