<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
	<app-buttons
		(save)="submit()"
		(close)="destroy()"
		(discard)="discard()"
		[buttonsWrap]="'panel'"
		[modificar]="modificar"
	></app-buttons>
	<div class="editor-registro">
		<div class="divForm">
			<form [formGroup]="editRegistroForm" (onSubmit)="submit()">
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Nombre }}</mat-label>
						<input matInput formControlName="Nombre" />
						<mat-error *ngIf="validateFields('Nombre')">
							{{ getErrorMessage("Nombre") }}
						</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Descripcion }}</mat-label>
						<input matInput formControlName="Descripcion" />
						<mat-error *ngIf="validateFields('Descripcion')">
							{{ getErrorMessage("Descripcion") }}
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.MaximoInternet }}</mat-label>
						<input type="number" matInput formControlName="MaximoInternet" />
						<mat-error *ngIf="validateFields('MaximoInternet')">
							{{ getErrorMessage("MaximoInternet") }}
						</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>{{ translation.MinimoInternet }}</mat-label>
						<input type="number" matInput formControlName="MinimoInternet" />
						<mat-error *ngIf="validateFields('MinimoInternet')">
							{{ getErrorMessage("MinimoInternet") }}
						</mat-error>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.IntervaloControl }}</mat-label>
						<input type="number" matInput formControlName="IntervaloControl" />
						<mat-error *ngIf="validateFields('IntervaloControl')">
							{{ getErrorMessage("IntervaloControl") }}
						</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>{{ translation.LimiteIntervalo }}</mat-label>
						<input type="number" matInput formControlName="LimiteIntervalo" />
						<mat-error *ngIf="validateFields('LimiteIntervalo')">
							{{ getErrorMessage("LimiteIntervalo") }}
						</mat-error>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-label
						>{{ translation.ControlHorario
						}}<mat-slide-toggle
							matInput
							name="ControlHorario"
							formControlName="ControlHorario"
						></mat-slide-toggle>
					</mat-label>

					<mat-label
						>{{ translation.TiempoLimite
						}}<mat-slide-toggle
							matInput
							name="TiempoLimite"
							formControlName="TiempoLimite"
						></mat-slide-toggle>
					</mat-label>

					<mat-label
						>{{ translation.MostrarHorario
						}}<mat-slide-toggle
							matInput
							name="MostrarHorario"
							formControlName="MostrarHorario"
						></mat-slide-toggle>
					</mat-label>

					<mat-label
						>{{ translation.Internet
						}}<mat-slide-toggle
							matInput
							name="Internet"
							formControlName="Internet"
						></mat-slide-toggle>
					</mat-label>
				</div>
				<div class="form-group">
					<mat-label
						>{{ translation.ControlarAforo
						}}<mat-slide-toggle
							matInput
							name="ControlarAforo"
							formControlName="ControlarAforo"
						></mat-slide-toggle>
					</mat-label>

					<mat-label
						>{{ translation.IncluirLLegada
						}}<mat-slide-toggle
							matInput
							name="IncluirLLegada"
							formControlName="IncluirLLegada"
						></mat-slide-toggle>
					</mat-label>

					<mat-label
						>{{ translation.dePago }}
						<mat-slide-toggle
							name="dePago"
							formControlName="dePago"
						></mat-slide-toggle>
					</mat-label>

					<mat-label
						>{{ translation.DeBaja }}
						<mat-slide-toggle
							name="deBaja"
							formControlName="deBaja"
						></mat-slide-toggle>
					</mat-label>
				</div>

				<div style="margin: 20px 5px 0 5px; text-align: center">
					<mat-label>{{ translation.Centros }}</mat-label>

					<customan-dual-list
						[source]="centrosSinAsignar"
						[(destination)]="centrosAsignados"
						key="pkId"
						display="Nombre"
						[format]="format"
						[filter]="true"
					>
					</customan-dual-list>
				</div>
			</form>
		</div>
	</div>
</div>
