import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { Deserializable } from "@sharedV11/classes/deserializable";
import { CLienteAPi } from "@sharedV11/classes/userRequest/client_api";

export class GrupoTarifa {
	pkId: string;

	Nombre: string;
	Descripcion: string;
	TipoDescuento: string;
	ImporteDescuento: string | number;
	deBaja: string;

	platformsIds: string;
	daysOfWeek: string;

	ClientesIds: string;
	CentrosIds: string;
	TarifasIds: string;
}

export type BooleanNumber = 0 | 1 | "1" | "0";

export type StringNumber = string | number;

export type DiscountType = "DT" | "PR";

export type Mode = "I" | "U" | "D";

export interface DaysWeek {
	Lunes: BooleanNumber;
	Martes: BooleanNumber;
	Miercoles: BooleanNumber;
	Jueves: BooleanNumber;
	Viernes: BooleanNumber;
	Sabado: BooleanNumber;
	Domingo: BooleanNumber;
}

export interface IGrupoTarifaForm {
	pkId: string;
	Nombre: string;
	Descripcion: string;
	TipoDescuento: DiscountType;
	ImporteDescuento: string;
	tipoControlPlataforma: string[];
	diasSemana: string[];
	Habilitado: boolean;
}

export interface IFindReglaNegocioByPkIdAPI {
	clienteAPI: CLienteAPi;
	pkId: string;
}

export interface IReglaNegocio {
	pkId: BooleanNumber;

  Taquilla: BooleanNumber;

  Internet: BooleanNumber;

  IntAdmon: BooleanNumber;

	Activa: BooleanNumber;

	ValidarIdentificacion: BooleanNumber;

	NombreTabla: string;

	PkIdTabla: StringNumber;

	NombreTablaEntrada: string;

	iTablaEntradaId: StringNumber;

	TipoPeriodo: string;

	FechaVisibleInicio: string;

	FechaVisibleFin: string;

	Cantidad: StringNumber;

	ProcesoVentaId: StringNumber;

	TablaValidar: BooleanNumber;

	TipoDocumento: string;

	CantidadPor: string;

	EspectaculoId: StringNumber;

	FechaSesion: string;

	NominarEntradasPromo: BooleanNumber;

	NominarEntradas: BooleanNumber;

	Funcion: Mode;

  CentrosIds: string;
}


export type IUReglaNegocioAPI = {
	ConexionIacpos?: string;
	clienteAPI?: CLienteAPi;
} & IReglaNegocio;

/* Clases Response */

export class DatosResultReglaNegocio implements Deserializable {
	//ListaTarifas: { [key: string]: Tarifas };
	Lista: Map<string, IReglaNegocio>;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}
}

export class ReglasNegocioResponse implements Deserializable {
	DatosResult?: DatosResultReglaNegocio;
	Mensajes: Message[];

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}
}

export class ReglaNegocioToShow implements Deserializable {
	pkId: string;
	Activa?: BooleanNumber;
	Internet: BooleanNumber;
	Taquilla: BooleanNumber;
	TaqAutomatica: BooleanNumber;
	Admin: BooleanNumber; //IntAdmon
	AsignadoA: string;
	AsignadoId: string;
	AplicadoA: string;
	AplicadoId: string;
	TipoPeriodo: string;
	FechaVisibleInicio: string;
	FechaVisibleFin: string;
	CentrosIds?: string;
	CentrosNombres?: string;
	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

	assign(param, data) {
		this[param] = data;
	}
}
