<div>
  <div class="editor-registro" *ngIf="editorButacasForm">

      <div class="group-block">
        <div class="form-block" id="container">
          
          <section id="subdiv">
            
            <div id="helping">
              <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
              <p *ngIf="!loading" id="clicktime" style="position:absolute; margin-left:20px; pointer-events: none;">{{helpmsg}}</p>
            </div>
          <div *ngIf="contextual" class="d-flex justify-content-right align-items-center" id="contextualzone" >

              <div class="btn-map2" style="cursor: pointer;" >
                <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
                    <mat-button-toggle value="red" id="popuprender" (click)="drawer.toggle()" matTooltip="{{translation.leyenda}}">              
                      <mat-icon>info</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="red" id="popuprender" [matMenuTriggerFor]="menu" matTooltip="{{translation.operacionesmapa}}">              
                      <mat-icon>menu</mat-icon>
                    </mat-button-toggle>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item [matMenuTriggerFor]="estados">
                          <mat-icon>dialpad</mat-icon>
                          <span>{{translation.CambiarEstado}}</span>
                        </button>
                        <button mat-menu-item [matMenuTriggerFor]="tiposbutacas">
                          <mat-icon>dialpad</mat-icon>
                          <span>{{translation.CambiarTipo}}</span>
                        </button>
                        <button mat-menu-item (click)="borrarSeleccion()">
                          <mat-icon>delete</mat-icon>
                          <span>{{translation.BorrarSeleccion}}</span>
                        </button>
                        <button mat-menu-item (click)="resetView()">
                          <mat-icon>adjust</mat-icon>
                          <span>{{translation.CentrarMapa}}</span>
                        </button>
                      </mat-menu>
                      <mat-menu #estados="matMenu">
                        <button  mat-menu-item *ngFor="let item of listaEstadosButacas" (click)="guardarButacasSinNumerarMenuEstado(item.Id)">{{item.Nombre}}</button>
                      </mat-menu>
                      <mat-menu #tiposbutacas="matMenu">
                          <button mat-menu-item *ngFor="let item of listaTiposButacas" (click)="guardarButacasSinNumerarMenuTipo(item.Id)">{{item.Nombre}}</button>
                      </mat-menu> 
                    <mat-button-toggle id="popuprendermas" [matMenuTriggerFor]="modomap"  *ngIf="multiselecction" matTooltip="{{translation.modomapa}}">
                      <mat-icon>auto_fix_high</mat-icon>  
                    </mat-button-toggle>  
                    <mat-button-toggle id="popuprendermas" [matMenuTriggerFor]="modomap"  *ngIf="!multiselecction" matTooltip="{{translation.modomapa}}">
                      <mat-icon>open_with</mat-icon>  
                    </mat-button-toggle>  

                    <mat-menu #modomap="matMenu">
                      <button mat-menu-item (click)="selectionMulti(true)">
                        <mat-icon>auto_fix_high</mat-icon>
                        <span>{{translation.HerramientaSeleccion}}</span>
                      </button>
                      <button mat-menu-item (click)="selectionMulti(false)">
                        <mat-icon>open_with</mat-icon>
                        <span>{{translation.Navegarmapa}}</span>
                      </button>
                    </mat-menu>

                    <mat-button-toggle id="popuprendermenos" (click)="setZoom('down')" matTooltip="{{translation.alejarmapa}}">              
                      <mat-icon>zoom_out</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle id="popuprendermas" (click)="setZoom('up')" matTooltip="{{translation.acercarmapa}}">
                      <mat-icon>zoom_in</mat-icon>  
                    </mat-button-toggle>
                 </mat-button-toggle-group>
              </div>
            </div>

            <mat-drawer #drawer class="example-sidenav" mode="side" style="opacity: 0.85;height: fit-content;">
              <mat-icon (click)="drawer.toggle()" style="width: 100%;text-align: end;cursor: pointer;">clear</mat-icon>
              <div class="butacas">
                <div class="tipo-butaca" *ngFor="let item of getEstadosButacas">
                  <div class="cuadro-tipo" [style.background]="item.Color"></div>
                  <div class="text-tipoButaca">{{item.Descripcion}} / {{traerEstado(item.EstadoButaca)}}</div>
                </div>
                <div class="tipo-butaca">
                  <div class="cuadro-tipo color-seleccionada"></div>
                  <div class="text-tipoButaca">Seleccionada</div>
                </div>
              </div>
            </mat-drawer>

          <svg id="mapaButacas" style="width: 100%; height: 100%"></svg>
          </section>
        </div>
        <div class="form-block">
        </div>
      </div>
 
  </div>
</div>

