import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogoPopUp } from "@sharedV11/components/alert/dialog/popup.service";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EditCategoriasService } from "@sharedV11/services/components/Categorias/edit-categorias.service";
import { ClientService } from "@sharedV11/services/components/client/client.service";
import { EditTipoCodigoPromocionesService } from "@sharedV11/services/components/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.service";
import { EditClientGroupService } from "@sharedV11/services/components/edit_client_group/edit-client-group.service";
import { GrupoTipoBonoV11Service } from "@sharedV11/services/components/entradas-y-promociones/grupo-tipo-bono-v11/grupo-tipo-bono-v11.service";
import { ItemsMotivosGruposV11Service } from "@sharedV11/services/components/entradas-y-promociones/items-motivos-grupos-v11/items-motivos-grupos-v11.service";
import { BonoTypeService } from "@sharedV11/services/components/entradas-y-promociones/tipobono/bono-type.service";
import { EditDiasdeCierreService } from "@sharedV11/services/components/festivos/festivos";
import { EditGrupoRecintosService } from "@sharedV11/services/components/grupo-recinto/edit-grupo-recintos.service";
import { GruposActividadTallerService } from "@sharedV11/services/components/grupos-actividad-taller/grupos-actividad-taller.service";
import { RecintosVisitaService } from "@sharedV11/services/components/recintos/recintos-visita/recintos-visita.service";
import { TableInteractiveService } from "@sharedV11/services/filter/tableinteractive.service";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { TableComponent } from "../table/table.component";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { IdiomaGR } from "@sharedV11/classes/tarifaResponse/idiomaGR";
import { FormControl, FormGroup } from "@angular/forms";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import data from "src/app/shared/jsons/tables/tables.json";
import { DivisaObject } from "@sharedV11/interfaces/DivisaObject.interface";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "DD-MM-YYYY",
    dateA11yLabel: "DD-MM-YYYY",
    monthYearA11yLabel: "DD-MM-YYYY",
  },
};

@Component({
  selector: "app-table-interactive",
  templateUrl: "./table-interactive.component.html",
  styleUrls: ["./table-interactive.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  providers: [
    TableInteractiveService,
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class TableInteractiveComponent
  extends TableComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatTable) inttable: MatTable<any>;
  //@ViewChild("inttable") innerTable: QueryList<MatTable<any>>;
  //@ViewChild("ti_subtabla") ti_subtabla: TableInteractiveComponent;

  @Input() calco_pkId: string;
  @Input() calco_table: string;
  @Input() calco_extended: string;

  @ViewChildren(TableInteractiveComponent)
  ti_subtables: QueryList<TableInteractiveComponent>;

  @Output() guardatabla: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cargadatabla: EventEmitter<boolean> = new EventEmitter<boolean>();

  pagination = false;
  tablavalida = true;
  guardarcambios = false;
  insertando = false;
  copiaData;
  TipoCodigoPromocionalEntradas: any[];
  expandedElement: any | null;
  editorListaIdiomas: Array<IdiomaGR> = [];
  idiomasApp: Array<IdiomaGR> = [];
  editItemsMotivosGroupForm: FormGroup;
  modecalco: boolean = false;
  NumeroDecimales: number;
  modelcalcoextended: boolean = false;

  constructor(
    translator: Translator,
    tokenService: ApiTokenService,
    editClientService: EditClientGroupService,
    //filterService: FilterService,
    public tableInteractiveService: TableInteractiveService,
    public cdRef: ChangeDetectorRef,
    panelService: PanelService,
    public dialog: MatDialog,
    public servicioAPI: ConectarApiService,
    alertaService: AlertService,
    clientService: ClientService,
    public snackvar_service: SnackbarInfoService,
    imgroupService: ItemsMotivosGruposV11Service,
    grupoTipoBonoService: GrupoTipoBonoV11Service,
    grupoRecintosService: EditGrupoRecintosService,
    recintosVisitaService: RecintosVisitaService,
    gruposActividadTallerService: GruposActividadTallerService,
    categoriasService: EditCategoriasService,
    diasdeCierreService: EditDiasdeCierreService,
    TiposCodigoPromocionesService: EditTipoCodigoPromocionesService,
    bonoTypeBono: BonoTypeService,
    public httpErrorService: HttpErrorsService,
    route: ActivatedRoute,
    router: Router
  ) {
    super(
      translator,
      tokenService,
      editClientService,
      tableInteractiveService,
      cdRef,
      panelService,
      dialog,
      servicioAPI,
      alertaService,
      clientService,
      snackvar_service,
      imgroupService,
      grupoTipoBonoService,
      grupoRecintosService,
      recintosVisitaService,
      gruposActividadTallerService,
      categoriasService,
      diasdeCierreService,
      TiposCodigoPromocionesService,
      bonoTypeBono,
      httpErrorService,
      route,
      router
    );
  }

  ngOnInit(): void {
    this.NumeroDecimales = +localStorage.getItem("NumeroDecimales") || 2;
    this.setInitCalco();
    this.findTable();
    super.ngOnInit();
    this.getIdiomas([]);
  }

  setInitCalco() {
    if (
      this.calco_table &&
      this.calco_table != "" &&
      this.calco_pkId &&
      this.calco_pkId != ""
    ) {
      this.tableName = this.calco_table;
      this.parent_pkId = this.calco_pkId;
      if (this.calco_extended) {
        this.modelcalcoextended = true;
      }

      this.modecalco = true;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.tableInteractiveService.tableLoaded.subscribe((ev) => {
      this.tableLoaded = ev;
      this.copiaData = this.dataSource.data;
      this.cargadatabla.emit(true);
      this.dataSource.data.forEach((ds, i) => {
        this.dataSource.data[i]["updated"] = false;
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  findTable() {
    // aqui se podria añadir el campo actualizado en la tabla
    this.tableInteractiveService.findTable(this.tableName);

    switch (this.tableName) {
      case "tbTiposPromocionEntradas":
        break;
      default:
        break;
    }
  }

  somethingChanged(e, v, i, n) {
    console.log('SomethingChanged::', {
      e,
      v,
      i,
      n,
    });
    //actualizada la fila, se tendrá que guardar
    //this.guardarcambios = true;
    this.dataSource.data[i][n] = v;
    this.dataSource.data[i]["updated"] = true;
    this.validateRowRequired(i);

    let dataSource = this.dataSource;
    switch (this.tableName) {
      case "tbCodigoPromocionalTiposBonoEntradas":
      case "tbTiposCodigosPromocionales_tiposEntrada_calco":
      case "tbCodigoPromocionalEntradas":
      case "tbCodigoPromocionalTiposBonoEntradas_calco":
        this.recalcularLinea(i, v, n);

        break;
      default:
        break;
    }
  }

  recalcularLinea(i, v, n) {
    let tiposivas = JSON.parse(localStorage.getItem("TiposIvaCalendario"));

    let divisaObjectStorage = localStorage.getItem("divisaObject");

		let divisaObject: DivisaObject = divisaObjectStorage ? JSON.parse(divisaObjectStorage) : 
		{
			"PkId": "2",
			"pkId": "2",
			"Nombre": "EUROS",
			"simbolo": "€",
			"NumeroDecimales": "2",
			"OpsTarjeta": "True",
			"PosicionDivisa": "D",
			"SimboloDecimal": ",",
			"SimboloDerecha": "",
			"CodISO": "978",
			"SimbSeparacionMiles": ""
	}

  const { NumeroDecimales, SimboloDecimal } = divisaObject;

  const NumeroDecimalesInt = parseInt(NumeroDecimales);

  console.log(`recalcularlinea ${this.tableName}`,{
    i,
    v,
    n,
    divisaObject,
  })

  if (v.toString().includes(",")) {
    v = v.toString().replace(",", ".");
  }

    let ivaval = "0";
    if (this.dataSource.data[i]["TipoIvaId"] != 0)
      ivaval = tiposivas.find(
        (f) => f.pkId == this.dataSource.data[i]["TipoIvaId"]
      ).Iva;

    let ivavalcom = "0";
    if (this.dataSource.data[i]["TipoIvaComisionId"] != 0)
      ivavalcom = tiposivas.find(
        (f) => f.pkId == this.dataSource.data[i]["TipoIvaComisionId"]
      ).Iva;

    console.log({
      ivaval,
      ivavalcom,
    })

    switch (n) {
      case "BaseImponible":
        this.dataSource.data[i]["Precio"] = (
          parseFloat(v) +
          v * (parseFloat(ivaval) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);

        this.dataSource.data[i]["IVA"] = (
          v *
          (parseFloat(ivaval) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);

        break;
      
      case "TipoIvaId":
        let bi = parseFloat(this.dataSource.data[i]["BaseImponible"].replace(",", "."));

        this.dataSource.data[i]["Precio"] = (
          bi +
          bi * (parseFloat(ivaval) / 100)
        ).toFixed(NumeroDecimalesInt).replace(".",SimboloDecimal);

        this.dataSource.data[i]["IVA"] = (
          bi *
          (parseFloat(ivaval) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);
        break;

      case "ComisionBI":
        this.dataSource.data[i]["Comision"] = (
          parseFloat(v) +
          v * (parseFloat(ivavalcom) / 100)
        ).toFixed(NumeroDecimalesInt).replace(".",SimboloDecimal);

        this.dataSource.data[i]["IvaComision"] = (
          v *
          (parseFloat(ivavalcom) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);

        break;
      case "TipoIvaComisionId":
        let bicom = parseFloat(this.dataSource.data[i]["ComisionBI"].replace(",", "."));

        this.dataSource.data[i]["Comision"] = (
          bicom +
          bicom * (parseFloat(ivavalcom) / 100)
        ).toFixed(NumeroDecimalesInt).replace(".",SimboloDecimal);

        this.dataSource.data[i]["IvaComision"] = (
          bicom *
          (parseFloat(ivavalcom) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);
        break;

      case "Precio":
        this.dataSource.data[i]["BaseImponible"] = (
          parseFloat(v) /
          (1 + parseFloat(ivaval) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);


        this.dataSource.data[i]["IVA"] = (
          parseFloat(this.dataSource.data[i]["BaseImponible"].replace(',', '.')) * (parseFloat(ivaval) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);
        break;

      case "Comision":
        this.dataSource.data[i]["ComisionBI"] = (
          parseFloat(v) /
          (1 + parseFloat(ivavalcom) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);
        this.dataSource.data[i]["IvaComision"] = (
          parseFloat(this.dataSource.data[i]["ComisionBI"].replace(',', '.')) *
          (parseFloat(ivavalcom) / 100)
        ).toFixed(4).replace(".",SimboloDecimal);
        break;
    }
    this.emitirTotales();
  }

  somethingChangedSlideToggle(e, v, i, n) {
    /* console.log('SomethingChangedSlideToggle::',{
      e,
      v,
      i,
      n,
    }); */

    /* if (
      (this.tableName == "tbTiposCodigosPromocionales_tiposEntrada" &&
        n == "DeBaja") ||
      (this.tableName == "tbTiposCodigosPromocionales_tiposPromociones" &&
        n == "DeBaja")
    ) {
      var prod;
      prod =
        this.tableName == "tbTiposCodigosPromocionales_tiposEntrada"
          ? document.getElementById("TipoEntradaId_" + i)
          : document.getElementById("TipoBonoId_" + i);

      const dialogRef = this.dialog.open(DialogoPopUp, {
        width: "250px",
        data: {
          titulo: this.translation.HeredarDeBajaEnCodigoPromocional,
          texto:
            this.translation.HeredarDeBajaEnCodigoPromocionaltxt1 +
            prod.value +
            this.translation.HeredarDeBajaEnCodigoPromocionaltxt2,
          eleccion: this.translation.Patatas,
          ok: this.translation.Confirmar,
          ko: this.translation.Cancelar,
        },
      });

      this.guardarcambios = true;
      dialogRef.afterClosed().subscribe((result) => {
        console.log('Dialog Result::', result);
        if (result === "SI") {
          this.dataSource.data[i]["HeredarDeBajaEnCodigoPromocional"] = "1";
        } else {
          this.dataSource.data[i]["HeredarDeBajaEnCodigoPromocional"] = "0";
        }
      });
    } */
    
    this.dataSource.data[i][n] = v;
    this.dataSource.data[i]["updated"] = true;
    this.validateRowRequired(i);
  }

  somethingChangedDate(e, v, i, n) {
    this.dataSource.data[i][n] = new FormControl(moment(v, "DD-MM-YYYY"));
    this.dataSource.data[i]["updated"] = true;
    this.validateRowRequired(i);
  }

  focusOutFunction(e, v, i, n, row) {

    //this.guardarcambios = true;

    //this.dataSource.data[i][n] = new FormControl(moment(v,"DD-MM-YYYY"));
    //this.dataSource.data[i]["updated"] = true;
    //this.validateRowRequired(i);

    console.log({
      e,
      v,
      i,
      n,
      row,
    })

    const columnsWithFourDecimals = [
      "BaseImponible",
      "IVA",
      "ComisionBI",
      "IvaComision",
    ];

    const columnsWithDecimals = [
      "BaseImponible",
      "IVA",
      "ComisionBI",
      "IvaComision",
      "Precio",
      "Comision",
    ]

    let divisaObjectStorage = localStorage.getItem("divisaObject");

		let divisaObject: DivisaObject = divisaObjectStorage ? JSON.parse(divisaObjectStorage) : 
		{
			"PkId": "2",
			"pkId": "2",
			"Nombre": "EUROS",
			"simbolo": "€",
			"NumeroDecimales": "2",
			"OpsTarjeta": "True",
			"PosicionDivisa": "D",
			"SimboloDecimal": ",",
			"SimboloDerecha": "",
			"CodISO": "978",
			"SimbSeparacionMiles": ""
	}

  const { NumeroDecimales, SimboloDecimal } = divisaObject;

  const NumeroDecimalesInt = parseInt(NumeroDecimales);

    let dataSource = this.dataSource;
    switch (this.tableName) {
      /*       case 'Calendariodias_tiposcontrol':
        break;
      case 'Calendariohoras_tiposcontrol':
        break; */
      case "tbTiposPromocionEntradas":
        //comprobamos porcentajes
        let porcentajeTotal = 0;
        dataSource.data.forEach((item) => {
          if (item.rPorcentaje == "") item.rPorcentaje = 0;
          if (!item.deleted) porcentajeTotal += parseFloat(item.rPorcentaje);
        });

        if (porcentajeTotal > 0 && porcentajeTotal === 100) {
          //dataSource.data.splice(dataSource.data.findIndex(item => item.pkId == row.pkId),1);
          //this.guardarTabla(dataSource.data);
        } else {
          this.snackvar_service.openSnackBar(
            this.translation.ElPorcentajeHaDeSerDelCienPorciento,
            "",
            "red-snackbar"
          );
          this.guardarcambios = false;
          /*             const dialogRef = this.dialog.open(DialogoPopUp, {
              width: '250px',
              data: {
                titulo: this.translation.PorcentajeIncorrecto,
                texto: this.translation.ElPorcentajeHaDeSerDelCienPorciento,
                ok: this.translation.Confirmar
              }
            }); */
        }
        break;

      case "tbCodigoPromocionalTiposBonoEntradas_calco":
      case "tbTiposCodigosPromocionales_tiposEntrada_calco":
      case "tbCodigoPromocionalTiposBonoEntradas":
      case "tbCodigoPromocionalEntradas":
        if (columnsWithDecimals.includes(n)) {
          let value = v ? v.toString().replace(SimboloDecimal, ".") : "0";
          this.dataSource.data[i][n] = parseFloat(value)
            .toFixed(
              columnsWithFourDecimals.includes(n) ? 4 : NumeroDecimalesInt
            )
            .replace(".", SimboloDecimal);
        }
        break;
      default:
        break;
    }
  }

  validateRowRequired(i) {
    this.guardarcambios = true;
    document.getElementById("row_" + i).classList.remove("requiredrow");
    //this.insertando = true;

    for (var key in this.dataSource.data[i]) {
      if (this.dataSource.data[i].hasOwnProperty(key)) {
        let col = this.table.columns.find((f) => f.name == key);

        if (col && col["required"] != undefined && col["required"] == true) {
          switch (col.type) {
            case "date":
              if (
                this.dataSource.data[i][key].value == undefined ||
                this.dataSource.data[i][key].value == "" ||
                !this.dataSource.data[i][key].value._isValid
              ) {
                this.guardarcambios = false;
                document
                  .getElementById("row_" + i)
                  .classList.add("requiredrow");
              } else {
                if (this.guardarcambios == true)
                  document
                    .getElementById("row_" + i)
                    .classList.remove("requiredrow");
                //this.insertando = false;
              }

              break;
            default:
              if (this.dataSource.data[i][key] == "") {
                this.guardarcambios = false;
                document
                  .getElementById("row_" + i)
                  .classList.add("requiredrow");
              } else {
                if (this.guardarcambios == true)
                  document
                    .getElementById("row_" + i)
                    .classList.remove("requiredrow");
                //this.insertando = false;
              }

              break;
          }
        }
      }
    }
  }

  guardarTabla(dataSourceData?) {
    if (dataSourceData) this.dataSource.data = dataSourceData;
    let porcentajeTotal = 0;

    switch (this.tableName) {
      case "tbTiposPromocionEntradas":
        var haylineas = false;
        this.dataSource.data.forEach((item) => {
          if (!item.deleted) haylineas = true;
          if (item.rPorcentaje == "") item.rPorcentaje = 0;
          porcentajeTotal += parseFloat(item.rPorcentaje);
        });

        if (!haylineas || porcentajeTotal == 100) {
          this.dataSource.data = this.dataSource.data.filter((f) => {
            return !f.deleted;
          });
          this.insertando = false;
          this.guardarcambios = false;
        } else {
          this.snackvar_service.openSnackBar(
            this.translation.ElPorcentajeHaDeSerDelCienPorciento,
            "",
            "red-snackbar"
          );
          this.guardarcambios = false;
        }

        break;

      case "tbCodigoPromocionalTiposBonoEntradas":
        this.dataSource.data.forEach((el) => {
          el.Precio = el.Precio.toString();
          el.Comision = el.Comision.toString();
          el.IVA = el.IVA.toString();
          el.pkId = el.pkId.toString();
        });
        this.dataSource.data = this.dataSource.data.filter((f) => {
          return !f.deleted;
        });
        this.insertando = false;
        this.guardarcambios = false;
        break;
      default:
        this.dataSource.data = this.dataSource.data.filter((f) => {
          return !f.deleted;
        });
        this.insertando = false;
        this.guardarcambios = false;
        break;
    }

    // eviar datos al servicio TODO
  }

  cancelarCambios() {
    super.ngOnInit();

    /*     this.dataSource.data = this.copiaData;
    this.dataSource.filter = "";

    this.cdRef.detectChanges();
    this.inttable.renderRows(); */
    this.guardarcambios = false;
    this.insertando = false;
  }

  actionTableManage(action, row, i) {
    /* console.log({
      action,
      row,
      i,
    }) */
    switch (action) {
      case "borrar":
        this.borrarFila(row, this.dataSource, i);
        break;

      default:
        break;
    }
  }

  anadirEntrada() {
    //if (!this.insertando) {
    this.guardarcambios = false;
    this.insertando = true;
    let nombreTabla = this.tableName;
    let subparentId = this.subparent_pkId;
    let fila = {};
    this.table.columns.forEach((c) => {
      if (c.name != "Actions") {
        switch (c.type) {
          case "number":
            if (c.name == "pkId") {
              fila[c.name] = "";
            } else if (
              c.name == "iNumEntradas" &&
              nombreTabla == "tbTiposPromocionEntradas"
            ) {
              fila[c.name] = "1";
            }
            if (
              c.name == "CodigoPromocionalTipoBonoId" &&
              nombreTabla == "tbCodigoPromocionalTiposBonoEntradas"
            ) {
              fila[c.name] = subparentId;
            } else {
              if (c["min"]) fila[c.name] = c["min"];
              else fila[c.name] = 0;
            }

            if (c.name == "rPorcentaje") fila[c.name] = "100";
            break;
          case "slidetoggle":
            fila[c.name] = false;
            break;
          case "select":
            fila[c.name] = "0";
            break;
          default:
            fila[c.name] = "";
            break;
        }
      }
    });
    console.log({
      fila,
      data: this.dataSource
    })
    this.dataSource.data.push(fila);

    this.dataSource.filter = "";
    //let c1 = this.inttable._getRenderedRows(this.inttable._rowOutlet);
    let trs = this.inttable._getRenderedRows(this.inttable._rowOutlet);
    //this.validateRowRequired(this.dataSource.data.length-1);
    /*
    let c11 = trs[trs.length-2];
    trs[0].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = true;
    trs[1].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = true; */
    //trs[2].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = false;
    let _that = this;
    setTimeout(function () {
      //trs[trs.length-1].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = false;
      /*       _that.table.columns.forEach( (c,i) => {
        if (c.name!="pkId" && c.name!="Actions") {
          if (c.type=="inputFilter") {
            trs[trs.length-1].children[i-1].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = false;
            //trs[trs.length-1].children[i-1].children[0].children[0].children[0].children[0].children[0].children[2].children[0].focus();
          } if (c.type=="select") {
            //trs[trs.length-1].children[i-1].children[0].setAttribute("disabled","false");
          } else {
            trs[trs.length-1].children[i-1].children[0]["disabled"] = false;
          }
          //trs[trs.length-1].children[i-1].children[0].setAttribute("focus","true");
        }
      }); */
    }, 1000);
  }

  borrarFila(row, dataSource, indexDataSource = 0): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.translation.BorrarRegistro,
        texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas,
        ok: this.translation.Confirmar,
        ko: this.translation.Cancelar,
      },
    });

    this.guardarcambios = true;
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "SI") {
        /*         this.dataSource.data.findIndex(item=>
          item.pkId == row.pkId)); */
   
        //this.dataSource.data = this.dataSource.data.splice(this.dataSource.data.findIndex(item => item.pkId == row.pkId),1);
        /*           this.dataSource.data = this.dataSource.data.splice(this.dataSource.data.findIndex(item=>
          item.pkId == row.pkId)); */

        // mat-table does not support detecting datasource change, have to manually render rows
        //this.inttable.renderRows();
        //this.cdRef.detectChanges();
        //this.innerTable.first.renderRows();
        switch (this.tableName) {
          case "Calendariodias_tiposcontrol":
            dataSource.data.splice(
              dataSource.data.findIndex((item) => item.pkId == row.pkId),
              1
            );
            this.servicioAPI
              .delCalendariosTiposControl(row.pkId, row.CodigoTipoControl)
              .subscribe((delcal) => {
                if (delcal.Mensajes != null && delcal.Mensajes.length > 0)
                  this.snackvar_service.openSnackBar(
                    this.translation[delcal.Mensajes[0].CodigoMensaje],
                    "",
                    "red-snackbar"
                  );
                else {
                  this.guardarTabla(dataSource.data);
                  this.reloadTable();
                }
              });
            break;
          case "Calendariohoras_tiposcontrol":
            dataSource.data.splice(
              dataSource.data.findIndex((item) => item.pkId == row.pkId),
              1
            );
            this.servicioAPI
              .delCalendariosTiposControl(row.pkId, row.CodigoTipoControl)
              .subscribe((delcal) => {
                if (delcal.Mensajes != null && delcal.Mensajes.length > 0)
                  this.snackvar_service.openSnackBar(
                    this.translation[delcal.Mensajes[0].CodigoMensaje],
                    "",
                    "red-snackbar"
                  );
                else {
                  this.guardarTabla(dataSource.data);
                  this.reloadTable();
                }
              });
            break;

          case "tbTiposPromocionEntradas":
            //comprobamos porcentajes
            let index = this.dataSource.data.findIndex(
              (item) => item.pkId == row.pkId
            );
            this.dataSource.data[index].deleted = true; //marcamos como borrada

            var porcentajeborrado = parseFloat(
              this.dataSource.data[index].rPorcentaje
            ); // guardamos su porcentaje
            this.dataSource.data[index].rPorcentaje = 0; // lo ponemos a 0

            var porcentajeTotal = 0;
            var haylineas = false;
            dataSource.data.forEach((item, j) => {
              if (item.rPorcentaje == "") item.rPorcentaje = 0;

              if (
                item &&
                item.pkId != row.pkId &&
                !item.deleted &&
                !haylineas
              ) {
                //pasamos el procentaje de la línea borrada a
                haylineas = true;
                this.dataSource.data[j].rPorcentaje =
                  parseFloat(this.dataSource.data[j].rPorcentaje) +
                  porcentajeborrado;
                document.getElementById(
                  "col_" + j + "_rPorcentaje"
                ).style.color = "red";
              }

              if (item && item.pkId != row.pkId && !item.deleted) {
                //haylineas = true;
                porcentajeTotal += parseFloat(item.rPorcentaje);
              }
            });

            if (porcentajeTotal > 0 && porcentajeTotal === 100) {
              document.getElementById("row_" + index).style.display = "none"; // borramos línea en pantalla
              dataSource.data.splice(
                dataSource.data.findIndex((item) => item.pkId == row.pkId),
                1
              );
              this.guardarTabla(dataSource.data);
            } else {
              if (!haylineas) {
                this.guardarcambios = false;
                //document.getElementById("row_"+index).style.display = "block";
                this.dataSource.data[index].deleted = false;
                this.snackvar_service.openSnackBar(
                  this.translation.Necesitaalmenosunaentrada,
                  "",
                  "red-snackbar"
                );
              } else {
                this.guardarcambios = false;
                this.dataSource.data[index].deleted = false;
                this.snackvar_service.openSnackBar(
                  this.translation.ElPorcentajeHaDeSerDelCienPorciento,
                  "",
                  "red-snackbar"
                );
              }
            }
            break;
          default:
            if (row.pkId  && row.pkId !== 0) {
              this.dataSource.data.splice(
                this.dataSource.data.findIndex((item) => item.pkId == row.pkId),
                1
              );
            } else {
              this.dataSource.data.splice(indexDataSource, 1);
            }

            this.guardarTabla(dataSource.data);
            break;
        }
      }
    });
  }

  closeInputFilter(enventValues, i, n) {
    let saveRow = false;
    let message = this.translation["CAMPOS_INVALIDOS"];
    let columnValidate = "";

    const { methods } = enventValues;

    switch (this.tableName) {
      case "tbTiposCodigosPromocionales_tiposEntrada_insert":
      case "tbTiposCodigosPromocionales_tiposEntrada":

        saveRow = !this.isRepeatField(i, "TipoEntradaId", enventValues.value);
        message = this.translation["ERROR_ENTRADA_REPETIDA"];
        columnValidate = "TipoEntradaId";

        break;

      case "tbTiposCodigosPromocionales_tiposPromociones_insert":
      case "tbTiposCodigosPromocionales_tiposPromociones":
        saveRow = !this.isRepeatField(i, "TipoBonoId", enventValues.value);
        message = this.translation["ERROR_PROMOCION_REPETIDA"];
        columnValidate = "TipoBonoId";
        break;
      default:
        saveRow = true;
        break;
    }

    if (!saveRow) {
      this.snackvar_service.openSnackBar(
        message,
        "",
        "red-snackbar"
      );

      methods.markFormAsInvalid("answer", {
        incorrect: true,
      })
      this.guardarcambios = false;
    }

    if (saveRow) {
      this.dataSource.data[i][n] = enventValues.value;
      this.validateRowRequired(i);
    }
  }


  isRepeatField(indexNewRow, nameColumn, value) {
    let repetido = false;

    if (this.dataSource.data.length == 0) return false;

    this.dataSource.data.forEach((item, j) => {
      if (indexNewRow != j && item[nameColumn] == value) {
        repetido = true;
      }
    });

    return repetido;
  }

  expandRow(e, row) {
    if (e.target.innerHTML != "more_vert") {
      this.expandedElement = this.expandedElement === row ? null : row;
    }
  }

  getIdiomas(idiomasgrupo?, mode?) {
    this.servicioAPI.getIdiomas().subscribe(
      (losidiomas) => {
        losidiomas.DatosResult.ListaIdiomas.map((idioma) => {
          let idiomaselect = new IdiomaGR();
          idiomaselect.CodIdioma = idioma.CodIdioma;
          idiomaselect.Nombre = idioma.Nombre;
          this.idiomasApp.push(idiomaselect);

          if (idiomasgrupo) {
            let idiomaObj = idiomasgrupo.find(
              (i) => i.CodIdioma == idioma.CodIdioma
            );

            this.editorListaIdiomas.push(idioma);
            //this.editItemsMotivosGroupForm.addControl('nombre' + idioma.CodIdioma, new FormControl(idiomaObj?idiomaObj.Nombre:""));
            //this.editItemsMotivosGroupForm.addControl('descripcion' + idioma.CodIdioma, new FormControl(idiomaObj?idiomaObj.Descripcion:""));
          }

        });

        //this.itemsMotivosGroupProfile = this.editItemsMotivosGroupForm.value
        //this.valuedChanged();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Descripcion =
      evento;
    //
  }

  getDatoIdioma(v, codId, campo) {
    return v.Idiomas.find((f) => f.CodIdioma == codId)[campo];
  }

  idiomaChanged(e, v, i, codid, campo) {
    this.guardarcambios = true;
    this.dataSource.data[i]["Idiomas"].find((f) => f.CodIdioma == codid)[
      campo
    ] = v;
  }

  submit(parent, cerrar) {
    parent.guardarTabla();
  }

  ActualizarTotales(e) {
    switch (this.tableName) {
      case "tbCodigoPromocionalTipoBono":
      case "tbTiposCodigosPromocionales_tiposPromociones_calco":
        console.log({
          event: e,
          table: this.tableName,
        })
        if (e && e !== true) {
          this.dataSource.data.forEach((row) => {
            console.log({
              row
            })
            if (row.pkId == e.TipoCodigoPromocionalTipoBonoId)
              row.BaseImponible = e.BaseImponibleTotal.toString();
            row.Precio = e.PrecioTotal.toString();
            row.ComisionBI = e.BIComisionTotal.toString();
            row.Comision = e.ComisionTotal.toString();
          });
        }
        break;
      default:
        break;
    }

    /*     this.dataSource.data.find(row => {
      row.pkId==e.TipoCodigoPromocionalTipoBonoId
    }).BaseImponible = e.BaseImponibleTotal.toString(); */
  }
  /*   getTable() {
    this.tableInteractiveService.table$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.table = data;
      if (this.table.columns != null) {
        this.columnsToDisplay = [];
        this.showTable = false;
        this.displayedColumnsFunction();
      }
    });
  }

  displayedColumnsFunction() {
    this.table.columns.map(elem => {
      if (elem.columnActive != '' && elem.column) {
        this.showTable = true;
        this.columnsToDisplay.push(elem.name);
      }
    });
  } */

  /*
  //modifica el estado para que se pueda
  ocultarCeldas(celda) {
    celda.editar = true;
    this.filas.forEach(f => {
      f.forEach(c => {
        if (c != celda) c.editar = false;
      });
    });
  } */
}
