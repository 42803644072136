import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Grupos_actividad_taller } from "@sharedV11/classes/recintos/grupos-actividad-taller/grupos-actividad-taller";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { GruposActividadTallerService } from "@sharedV11/services/components/grupos-actividad-taller/grupos-actividad-taller.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-grupos-actividad-taller-v11",
  templateUrl: "./edit-grupos-actividad-taller-v11.component.html",
  styleUrls: ["./edit-grupos-actividad-taller-v11.component.scss"],
})
export class EditGruposActividadTallerV11Component implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: any;
  @Input() mode: any;
  @Input() modificar: boolean;

  editGruposActividadTallerForm: FormGroup;
  gruposActividadTaller: Grupos_actividad_taller;

  translation: any;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  constructor(
    private translator: Translator,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private gruposActividadTallerService: GruposActividadTallerService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    //this.initializeForms()
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }

  get formControls() {
    return this.editGruposActividadTallerForm.controls;
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeFormWithoutData() {
    this.editGruposActividadTallerForm = this.fb.group({
      pkId: [""],
      Nombre: ["", [Validators.required]],
      Descripcion: [""],
    });
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.gruposActividadTaller = this.editGruposActividadTallerForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(
        this.gruposActividadTaller
      );
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != undefined) {
      this.serviceCall(pkId);
    }
  }

  valuedChanged() {
    this.editGruposActividadTallerForm.valueChanges.subscribe((value) => {
      if (this.gruposActividadTaller != null) {
        this.panelService.setDiscardChanges(false);
        if (
          JSON.stringify(value) === JSON.stringify(this.gruposActividadTaller)
        ) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  serviceCall(pkId) {
    this.gruposActividadTallerService
      .findGrupoActividadTallerPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (gruposactividadtaller) => {
          let grupoActividadTaller =
            gruposactividadtaller["DatosResult"][
              "customanGrupoActividadTaller"
            ][0];
          if (grupoActividadTaller != null) {
            this.changeValueForm(grupoActividadTaller);
            this.gruposActividadTaller = grupoActividadTaller;
            this.gruposActividadTaller.CentrosIds =
              grupoActividadTaller.CentrosIds;
            this.valuedChanged();
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(
              this.gruposActividadTaller
            );
            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  changeValueForm(grupoTaller: Grupos_actividad_taller) {
    this.formControls["pkId"].setValue(grupoTaller.pkId);
    this.formControls["Nombre"].setValue(grupoTaller.Nombre);
    this.formControls["Descripcion"].setValue(grupoTaller.Descripcion);
  }

  submit() {
    let grupo: Grupos_actividad_taller =
      this.editGruposActividadTallerForm.value;

    this.editGruposActividadTallerForm.markAllAsTouched();
    if (this.editGruposActividadTallerForm.valid) {
      this.manageApi(grupo, this.mode);
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.loading = false;
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.gruposActividadTaller);
  }

  manageApi(grupo: Grupos_actividad_taller, mode) {
    this.loading = true;
    grupo.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );

    switch (mode) {
      case "I":
        //insert
        grupo.pkId = "0";
        this.InsGrupoActividadTaller(grupo);
        break;
      case "U":
        //update
        this.UpdGrupoActividadTaller(grupo);
        break;
      case "D":
        //duplicate
        grupo.pkId = "0";
        this.InsGrupoActividadTaller(grupo);
        break;
      default:
        break;
    }
  }
  UpdGrupoActividadTaller(gruporecinto) {
    this.gruposActividadTallerService
      .updGruposGrupoActividadTaller(gruporecinto, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  InsGrupoActividadTaller(gruporecinto) {
    this.gruposActividadTallerService
      .insGruposGrupoActividadTaller(gruporecinto, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  /*   insUpdGrupoActividadTaller(grupo: Grupos_actividad_taller){
    this.gruposActividadTallerService.insUpgGrupoActividadTaller(grupo).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response);
    });
  } */

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      console.table(response.Mensajes);
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }

  getErrorMessage(field: string) {
    let mensaje: string;
    let fc_pv = this.editGruposActividadTallerForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        mensaje = this.translation["CampoRequerido"];
      }
    }
    return mensaje;
  }
}
