import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  TipoClientes,
  tipos_cliente_Respuesta,
} from "@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { EditClientGroupService } from "@sharedV11/services/components/edit_client_group/edit-client-group.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-client-type",
  templateUrl: "./edit-client-type.component.html",
  styleUrls: ["./edit-client-type.component.scss"],
})
export class EditClientTypeComponent implements OnInit {
  public loading: boolean = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editGroupModelForm: FormGroup;
  tipoCliente: TipoClientes;

  respuestaAPI: tipos_cliente_Respuesta;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  constructor(
    private snackvar_service: SnackbarInfoService,
    private editClientService: EditClientGroupService,
    private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();

    this.manageForm(this.pkId, this.mode);
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }

  initializeFormWithData(tipoClientes: TipoClientes) {
    this.editGroupModelForm = this.fb.group({
      pkId: [tipoClientes.pkId],
      Codigo: [tipoClientes.Codigo],
      Nombre: [tipoClientes.Nombre],
      Afiliado: [tipoClientes.Afiliado]
    });
  }

  initializeFormWithoutData() {
    this.editGroupModelForm = this.fb.group({
      pkId: [""],
      Codigo: [
        "",
        [Validators.required, Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)],
      ],
      Nombre: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]],
      Afiliado: [false, Validators.required]
    });
  }

  changeValueForm(tipoClientes: TipoClientes) {
    this.formControls["pkId"].setValue(tipoClientes.pkId);
    this.formControls["Codigo"].setValue(tipoClientes.Codigo);
    this.formControls["Nombre"].setValue(tipoClientes.Nombre);
    this.formControls["Afiliado"].setValue(Number(tipoClientes.Afiliado) === 1 ? true : false);
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.tipoCliente = this.editGroupModelForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      //this.finduser(pkId)
      this.findClientType();
    }
  }

  get formControls() {
    return this.editGroupModelForm.controls;
  }

  submit() {
    let tipoClientes: TipoClientes = {
      ...this.editGroupModelForm.value,
      Afiliado: this.editGroupModelForm.get("Afiliado").value === true ? 1 : 0,
    }

    console.log('tipoCliente submit::', tipoClientes);

    //tipoClientes.PkId = this.tipoCliente.PkId;
    this.editGroupModelForm.markAllAsTouched();
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editGroupModelForm.valid) {
      this.manageApi(tipoClientes);
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }
  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.tipoCliente);
  }

  manageApi(grupo_cl: TipoClientes) {
    grupo_cl.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    this.loading = true;
    switch (this.mode) {
      case "I":
        this.InsClienteTipo(grupo_cl);
        //insert
        break;
      case "U":
        //update
        this.UpdClienteTipo(grupo_cl);
        break;
      case "D":
        //duplicate
        this.editGroupModelForm.value.pkId = "0";
        this.InsClienteTipo(grupo_cl);
        break;

      default:
        break;
    }
  }

  UpdClienteTipo(clientegrupo) {
    this.editClientService
      .updTiposCliente(clientegrupo, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  InsClienteTipo(clientegrupo) {
    this.editClientService
      .insTipoCliente(clientegrupo, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }
  }

  notEmptyFields(grupo_cl: TipoClientes): boolean {
    let res = true;
    if (grupo_cl.Nombre.length === 0 || grupo_cl.Codigo.length === 0) {
      res = false;
    }
    return res;
  }

  valuedChanged() {
    this.editGroupModelForm.valueChanges.subscribe((value) => {
      if (this.tipoCliente != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.tipoCliente)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  findClientType() {
    this.editClientService.findTipoClienteByPkId(this.pkId).subscribe(
      (response) => {
        let tipoClientes: TipoClientes =
          response["DatosResult"]["tipoClientes"][0];
        if (tipoClientes != null) {
          this.changeValueForm(tipoClientes);
          this.tipoCliente = this.editGroupModelForm.value;
          this.tipoCliente.CentrosIds = tipoClientes.CentrosIds;
          this.valuedChanged();
          this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
          this.centrosAsignados = this.gsCentros.daTiposCentros(
            this.tipoCliente
          );
          this.loading = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return fc_pv[field].errors != null ? true : false;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editGroupModelForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (field === "Codigo") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloNumeros"];
        }
      } else if (field === "Nombre") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloLetras"];
        }
      }
    }
    return "Error";
  }

  existErrors() {
    return this.formControls.CodigoGruposClientes.errors ||
      this.formControls.NombreGruposClientes.errors
      ? true
      : false;
  }
}
