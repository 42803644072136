import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { Deserializable } from "../deserializable";
import { CLienteAPi } from "../userRequest/client_api";

export class GrupoTarifa {
	pkId: string;

	Nombre: string;
	Descripcion: string;
	TipoDescuento: string;
	ImporteDescuento: string | number;
	deBaja: string;

	platformsIds: string;
	daysOfWeek: string;

	ClientesIds: string;
	CentrosIds: string;
	TarifasIds: string;
}

export type BooleanNumber = 0 | 1 | '1' | '0';

export type DiscountType = 'DT' | 'PR';

export type Mode = 'I' | 'U' | 'D';

export interface DaysWeek {
	Lunes: BooleanNumber;
	Martes: BooleanNumber,
	Miercoles: BooleanNumber,
	Jueves: BooleanNumber,
	Viernes: BooleanNumber,
	Sabado: BooleanNumber,
	Domingo: BooleanNumber,
}

export interface IGrupoTarifaForm {
	pkId: string;
	Nombre: string;
	Descripcion: string;
	TipoDescuento: DiscountType;
	ImporteDescuento: string;
	tipoControlPlataforma: string[];
	diasSemana: string[];
	Habilitado: boolean;
}

export interface IFindGrupoTarifaByPkIdAPI {
  clienteAPI: CLienteAPi;
  pkId: string;
}

export interface IGrupoTarifa {
	pkId: string;
	Nombre: string;
	Descripcion: string;
	Habilitado?: BooleanNumber;
	Internet: BooleanNumber;
	Taquilla: BooleanNumber;
	TaqAutomatica: BooleanNumber;
	IntAdmon: BooleanNumber;
	Lunes: BooleanNumber;
	Martes: BooleanNumber;
	Miercoles: BooleanNumber;
	Jueves: BooleanNumber;
	Viernes: BooleanNumber;
	Sabado: BooleanNumber;
	Domingo: BooleanNumber;
	TipoDescuento: DiscountType;
	Descuento: number;
	Funcion?: Mode;
	CentrosIds?: string;
	CentrosNombres?: string;
	Tarifas?: string;
	Clientes?: string;
}

export interface IGrupoTarifaResponse {
	pkId: string;
	Nombre: string;
	Descripcion: string;
	Habilitado?: BooleanNumber;
	Internet: BooleanNumber;
	Taquilla: BooleanNumber;
	TaqAutomatica: BooleanNumber;
	IntAdmon: BooleanNumber;
	Lunes: BooleanNumber;
	Martes: BooleanNumber;
	Miercoles: BooleanNumber;
	Jueves: BooleanNumber;
	Viernes: BooleanNumber;
	Sabado: BooleanNumber;
	Domingo: BooleanNumber;
	TipoDescuento: DiscountType;
	Descuento: string;
	CentrosIds?: string;
	CentrosNombres?: string;
	Tarifas?: string;
	Clientes?: string;
}

export type IUGrupoTarifaAPI = {
	ConexionIacpos?: string;
	clienteAPI?: CLienteAPi;
} & IGrupoTarifa;



/* Clases Response */



export class DatosResultGrupoTarifa implements Deserializable {
	//ListaTarifas: { [key: string]: Tarifas };
	Lista : Map<string, IGrupoTarifaResponse>;
	
		deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}
}

export class GrupoTarifaResponse implements Deserializable {
	DatosResult?: DatosResultGrupoTarifa;
	Mensajes: Message[];

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}
}


export class GrupoTarifaToShow implements Deserializable {
	pkId: string;
	nombre: string;
	Descripcion: string;
	Habilitado?: BooleanNumber;
	Internet: BooleanNumber;
	Taquilla: BooleanNumber;
	TaqAutomatica: BooleanNumber;
	Admin: BooleanNumber; //IntAdmon
	Lunes: BooleanNumber;
	Martes: BooleanNumber;
	Miercoles: BooleanNumber;
	Jueves: BooleanNumber;
	Viernes: BooleanNumber;
	Sabado: BooleanNumber;
	Domingo: BooleanNumber;
	TipoDescuento: DiscountType;
	Descuento: string;
	CentrosIds?: string;
	CentrosNombres?: string;
	Tarifas?: string;
	Clientes?: string;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}


	assign(param,data){
		this[param] = data
	}
	
}