import { Injectable } from "@angular/core";
import esES from "../../languages/es-ES.json";
import caES from "../../languages/ca-ES.json";
import ptPT from "../../languages/pt-PT.json";
import frFR from "../../languages/fr-FR.json";
import enGB from "../../languages/en-GB.json";

type typeTranslate = "UPPER" | "LOWER" | "CAPITALIZE";

@Injectable({
  providedIn: "root",
})
export class Translator {

  data: any;

  GetTranslations() {
    const language = localStorage.getItem("Language");

    switch (language) {
      case "es-ES":
        this.data = esES;
        return esES;
      case "ca":
        this.data = caES;
        return caES;
      case "fr-FR":
        this.data = frFR;
        return frFR;
      case "pt-PT":
        this.data = ptPT;
        return ptPT;
      case "en-GB":
      case "en":
        this.data = enGB;
        return enGB;
      default:
        localStorage.setItem("Language", "es-ES");
        this.data = esES;
        return esES;
    }
  }

  translateV2(key: string, typeTransform: typeTranslate = 'UPPER', data?: any) {
    const objectTranslate = data ? data : this.data;
    const value: string = objectTranslate[key] || key;

    switch (typeTransform) {
      case 'UPPER':
        return value.toUpperCase();
      case 'LOWER':
        return value.toLowerCase();
      case 'CAPITALIZE':
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      default:
        return value;
    }
  }
}
